import { Container, Hidden } from '@mui/material';
import React, { useState, useEffect } from 'react';
import "./App.css"
import { makeStyles } from '@material-ui/core/styles';
import { Header } from './components/Header';
import { FeaturedGames } from './components/FeaturedGames';
import { Footer } from './components/Footer';
import { AddFeaturedGames } from './components/AddFeaturedGames';
// import AdminLogin from './components/AdminLogin';
import AdminBase from './components/AdminBase';
import Uploads from './components/Upload';
import TodoList from './components/TodoList';
import { UploadImage } from './components/UploadImage';
import LinearDeterminate from './components/ProgressBar';
import { About } from './components/About';
import { Career } from './components/Career';
import { Banner } from './components/Banner';
// import {ThemeProvider} from "styled-components";
import { useDarkMode } from "./components/useDarkMode"
import { GlobalStyles } from "./components/Globalstyle";
import { lightTheme, darkTheme } from "./components/Themes"
import Toggle from "./components/Toggler"
// import CircularStatic from './components/PercentSpinner';
import Divider from '@mui/material/Divider';
import { AllGameList } from './components/AllGameList';
import { ThemeProvider } from 'styled-components';
import { BlackSection } from './components/BlackSection';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { Route, BrowserRouter as Routes } from 'react-router-dom';
import { TermsCondition } from './components/TermsCondition';
import BarLoader from "react-spinners/BarLoader";
import logob from './logob.png';
import { ContactUs } from './components/ContactUs';
const useStyles = makeStyles(theme => ({
  body: {
    margin: 0,
    padding: 0,

  }

}))
function App() {
  const classes = useStyles();
  const [show, setShow] = useState(true)
  const [videos, setVideos] = useState([]);
  const [theme, themeToggler, mountedComponent] = useDarkMode();
  let [loading, setLoading] = useState(false);
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  let [color, setColor] = useState("#000");


  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, []);

  if (!mountedComponent) return <div />
  return (
    <div>
      {
        loading ?
          <div className='preloaderp'>
            {/* <img  className="preLeoderL1"src={logob} /> */}
            <img  className=""src={logob}  style={{width:"100px",height:"100px" ,marginBottom:"20px"}}/>
            < BarLoader
              size={20}
              color={color}
              loading={loading}
            />

          </div > :
          <ThemeProvider theme={themeMode}>
            <>
              <GlobalStyles />
              <div className="wrapper" style={{ overflow: "hidden" }}>
                <Toggle theme={theme} toggleTheme={themeToggler} />
                <Routes>
                  <Route exact path="/">
                    <Header className="header" />
                    <Banner />
                    <Divider className="divider" />
                    <FeaturedGames />
                    <Divider className="divider" />
                    <About />
                    <Divider className="divider" />
                    <Career />
                    <Divider className="divider" />
                    <ContactUs/>
                    <Divider className="divider" />
                    <Footer />
                  </Route>
                  <Route exact path="/gamelist">
                    <AllGameList />
                  </Route>
                  <Route exact path="/leorus/admin">
                    <AdminBase />
                  </Route>
                  <Route exact path="/privacy.html">
                    <PrivacyPolicy />
                  </Route>
                  <Route exact path="/terms.html">
                    <TermsCondition />
                  </Route>

                </Routes>
              </div>
            </>
          </ThemeProvider>
      }
    </div>
  );
}

export default App;
