
import { getAuth } from "@firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/storage'
import { getStorage,ref  } from "firebase/storage";
import { initializeApp } from "firebase/app";

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAyiWNduC303iLw8W43eZvCMsQWPsylLO0",
//   authDomain: "leorusgames-web.firebaseapp.com",
//   projectId: "leorusgames-web",
//   storageBucket: "leorusgames-web.appspot.com",
//   messagingSenderId: "324756837840",
//   appId: "1:324756837840:web:5137cd3aac250ebf0c55d5",
//   measurementId: "G-ZCW0TJG16V"
// };
const firebaseConfig = {
  apiKey: "AIzaSyBR_8f33smhtafpmhsI_uo5uqx6UiPoS1U",
  authDomain: "leorustechkk.firebaseapp.com",
  databaseURL: "https://leorustechkk-default-rtdb.firebaseio.com",
  projectId: "leorustechkk",
  storageBucket: "leorustechkk.appspot.com",
  messagingSenderId: "979559310342",
  appId: "1:979559310342:web:39c234e4fe80758a3b4f87",
  measurementId: "G-D0LNJVD8VB"
};

// Initialize Firebase

export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = firebase.firestore(app)
export const database = firebase.database().ref('FeaturedGames');

export const storage =getStorage(app)



