// Import the functions you need from the SDKs you need



import { Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import "../components/Adminpage.css"
import { Alert, Snackbar } from '@mui/material';
import AdminBase from "./AdminBase"
import logob from '../logob.png';
import logow from '../logow.png';
import LogoutIcon from '@mui/icons-material/Logout';
export const AdminLogin = (props) => {
  const [enterr, setEnterr] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const {
    open,
    setOpen,
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
  } = props



  return (
    <div className="adminBody">
      <div className="login-box">
        <h2><img src={logow} alt="leorus game" className="logo" /></h2>
        <h2><img src={logob} alt="leorus game" className="logo2" /></h2>
        <form>
          <div className="user-box">
            <input type="text" name="" required value={email} onChange={(e) => setEmail(e.target.value)} />
            <label>Username</label>
          </div>
          <div className="user-box">
            <input type="password" name="" required value={password} onChange={(e) => setPassword(e.target.value)} />
            <label>Password</label>
          </div>
          <>

          </>
          <Button onClick={handleLogin} className="sBtn">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <p style={{ margin: 0 }}>submit</p>
          </Button>
        </form>


      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          invalid credantials
        </Alert>
      </Snackbar>
    </div>
  )
}
export default AdminLogin;
