export const lightTheme = {
    body: '#FFF',
    text: '#363537',
    toggleBorder: '#FFF',
    background: 'linear-gradient(#39598A, #79D7ED)',
  }
  
  export const darkTheme = {
    body: '#000',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#999',
    none:"none",
    white:"#fff",
    block:"block",
    section1:"rgb(0, 30, 60)",
    containerCard:"rgb(10, 25, 41)",
    black:"#000",
    whiteShadow:"0 0 5px 1px #FFDB29",
    yellow:"#ffdb29",
    item:"rgb(0, 30, 60)",
    rgbWhite:" rgba(255, 255, 255, 20%)",
    rgbaLogin:"0 0 25px 0 rgba(255,255,255,.6)"
  }