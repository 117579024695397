import { Grid, makeStyles } from '@material-ui/core'
import { Container } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { NavLink } from 'react-router-dom'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
// import { fLink } from 'react-scroll'
const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: "#fff",
        padding: "20px 0",
        borderTop: " 1px solid #e1e4e8",
    },
    navflinks: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    navflink: {
        display: "inline-block",
        fontSize:"14px",
    },
    flink: {
        color: "#000",
        cursor: "pointer",
        textDecoration: "none"
    },
    sociallink: {
        display: "flex",
        justifyContent: "space-evenly",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        }

    },
    socialIcon: {
        color: "#000",
        cursor: "pointer",
        borderRadius: "2px",
        fontSize: "14px",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#000",
            borderRadius: "2px",
            transition: " all 0.1s linear",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0 5px"
        }
    }

}))
export const Footer = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.footer} ${"footer"}`}>
            <Container>
                <Box>
                    <Grid container spacing={4}>

                        <Grid item xs={12} md={9}>
                            <div className={classes.navflinks}>
                                <div className={`${classes.navflink} ${"navflink"}`}><NavLink to="/" className={`${classes.flink} ${"flink"}`} ><span className={classes.flinkSpan}>©2021 Leorus Games</span></NavLink></div>
                                <div className={`${classes.navflink} ${"navflink"}`} ><NavLink target="_blank" rel="noreferrer" to="terms.html" className={`${classes.flink} ${"flink"}`} ><span className={classes.flinkSpan}>Terms and Condition</span></NavLink> </div>
                                <div className={`${classes.navflink} ${"navflink"}`} ><NavLink target="_blank" rel="noreferrer" to="privacy.html" className={`${classes.flink} ${"flink"}`} ><span className={classes.flinkSpan}>Privacy Policy</span></NavLink> </div>

                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className={`${classes.sociallink} ${"sociallink"}`}>
                                <a href="https://www.facebook.com/leorusgames" target="_blank" rel="noreferrer" ><FacebookOutlinedIcon className={`${classes.socialIcon} ${"socialIcon"}`} /></a>
                                <a href="/#" target="_blank" rel="noreferrer"> <InstagramIcon className={`${classes.socialIcon} ${"socialIcon"}`} /></a>
                                <a href="https://www.linkedin.com/company/leorusgames" target="_blank" rel="noreferrer"><LinkedInIcon className={`${classes.socialIcon} ${"socialIcon"}`} /></a>
                                <a href="/#" target="_blank" rel="noreferrer"><TwitterIcon className={`${classes.socialIcon} ${"socialIcon"}`} /></a>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}
