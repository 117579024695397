import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  body {
    background:${({ theme }) => theme.body} ;
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;

                .header {   
                    background: ${({ theme }) => theme.body} !important;
                    color: ${({ theme }) => theme.text};
                    transition: all 0.50s linear;
                
                }
                .message {${({ theme }) => theme.white} !important;
                background-color:${({ theme }) => theme.white} !important;}
                .Typewriter{
                    color:${({ theme }) => theme.white} !important;
                }
                .appBar{
                    background: ${({ theme }) => theme.body} !important;
                    color: ${({ theme }) => theme.text};
                    transition: all 0.50s linear;
                
                }
                .leoruslogo{
                    display:${({ theme }) => theme.none} !important;
                    transition: all 0.50s linear;
                }
                .leoruslogo1{
                    display:${({ theme }) => theme.block} !important;
                    transition: all 0.50s linear;
                }
                .link{
                    color:${({ theme }) => theme.white} !important;
                    transition: all 0.50s linear;
                }
                .link:after{
                    background-color: ${({ theme }) => theme.white} !important;
                }
                .link.active{
                    border-bottom: 3px solid ${({ theme }) => theme.white} !important;
                }
                .featuredSection{
                    background: ${({ theme }) => theme.black} !important;
                    color: ${({ theme }) => theme.white};
                    transition: all 0.50s linear;
                }
                .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color:  ${({ theme }) => theme.black} !important;
                }
                .MuiInputLabel-outlined{
                    color: ${({ theme }) => theme.black} !important;
                }
                .box{
                    // background: ${({ theme }) => theme.item} !important;
                    // color: ${({ theme }) => theme.text} !important;
                    // transition: all 0.50s linear;
                    // border:2px solid ${({ theme }) => theme.white} !important; 
                }
                .featureGamesTitle{
                    color: ${({ theme }) => theme.black} !important;
                    transition: all 0.50s linear;
                }
                .item{
                    // background: ${({ theme }) => theme.item} !important;
                    // transition: all 0.50s linear;
                    // border-color:${({ theme }) => theme.black} !important;
                }
                .divider{
                    border-color:${({ theme }) => theme.white} !important;
                }
                .css-rmz4vk {
                    position:absolute;
                    top:-4px;
                    left:-5px;
                }
                .css-1rn30mb-MuiCircularProgress-root {
                    width: 57px  !important;
                    height: 57px !important;
                }
                .css-1u2w381-MuiModal-root-MuiDrawer-root {
                    position: unset;
                    z-index: 99999;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    overflow:hidden;
                    z-index: 99999;
                }
                .pBarValue{display:none !important;}
                .seeMore,
                .joinus {
                    background: ${({ theme }) => theme.black} !important;
                    border-color:${({ theme }) => theme.white} !important;
                    color: ${({ theme }) => theme.white} !important;
                    // transition: all 0.50s linear;
                }
                .sideBarMenu{
                    background: ${({ theme }) => theme.black} !important;
                    transition: all 0.50s linear;
                }
                .privacyTitle,
                .privacyTitle2,
                .privacyDescription
                {color:${({ theme }) => theme.white} !important;}
                .iconButton,
                .cancelicon {color:${({ theme }) => theme.white} !important;}
                .seeMore:hover,
                .joinus:hover{
                    background: ${({ theme }) => theme.white} !important;
                    border-color:${({ theme }) => theme.black} !important;
                    color: ${({ theme }) => theme.black} !important;
                }   
                .toggleBtn{
                    -webkit-box-shadow:${({ theme }) => theme.whiteShadow} !important;
                    box-shadow:${({ theme }) => theme.whiteShadow} !important;
                    background: ${({ theme }) => theme.yellow} !important;
                    transition: all 0.50s linear;
                }
                .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{z-index:1111 !important; display:none}
                .css-5ir5xx-MuiLinearProgress-bar1 {
                background-color: ${({ theme }) => theme.white} !important;
                }
                .css-y28f86 {
                    position: unset!important;
                }
        
                
                .gamelistAll .css-1r8wrcl-MuiLinearProgress-bar2{
                    background-color: ${({ theme }) => theme.white} !important;
                }
                .gamelistAll .css-eglki6-MuiLinearProgress-root {
                    background-color: ${({ theme }) => theme.rgbWhite} !important;
                }
                .bHtext1::after,
                .bHtext2::before,
                .bHtext2:after, .bHtext1:before {
                    color: ${({ theme }) => theme.white} !important;
                }
                .glow{
                    color: ${({ theme }) => theme.white} !important;
                }
                .modalContainer{
                    background-color:  ${({ theme }) => theme.black} !important;
                    transition: all 0.50s linear;
                }
                .footer{
                    background-color: ${({ theme }) => theme.black}  !important;
                    border-color:${({ theme }) => theme.white}  !important;
                    transition: all 0.50s linear;

                }
                .login-box{
                    background-color: ${({ theme }) => theme.white}  !important;
                    box-shadow: ${({ theme }) => theme.rgbaLogin}  !important;
                }
                .logo{
                    display:${({ theme }) => theme.none} !important;
                    transition: all 0.50s linear;
                }
                .logo2{
                    display:${({ theme }) => theme.block} !important;
                    transition: all 0.50s linear;
                }
                .login-box .sBtn span:nth-child(1) {
                  background: linear-gradient(90deg, transparent,${({ theme }) => theme.black});
                }
                .login-box .sBtn span:nth-child(2) {
                    background: linear-gradient(180deg, transparent, ${({ theme }) => theme.black} );
                  }
                  .login-box .sBtn span:nth-child(3) {
                    background: linear-gradient(270deg, transparent, ${({ theme }) => theme.black});
                  }
                  .login-box .sBtn span:nth-child(4) {
                    background: linear-gradient(360deg, transparent, ${({ theme }) => theme.black});
                  }
                  .login-box .sBtn:hover {
                    background: ${({ theme }) => theme.black};
                    color: ${({ theme }) => theme.white};
                    border-radius: 5px;
                    box-shadow: 0 0 5px ${({ theme }) => theme.black},
                                0 0 25px ${({ theme }) => theme.black},
                                0 0 50px ${({ theme }) => theme.black},
                                0 0 100px ${({ theme }) => theme.black};
                  }
                  .login-box .user-box input:focus ~ label,
                  .login-box .user-box input:valid ~ label {
                    color: ${({ theme }) => theme.black};
                  }
                  .login-box .user-box input {
                    color: ${({ theme }) => theme.black};
                    border-bottom: 1px solid ${({ theme }) => theme.black};
                  }
                  .login-box h2 {
                    color: ${({ theme }) => theme.black};
                  }
                  .login-box .user-box label {
                    color: ${({ theme }) => theme.black};
                  }
                  .login-box form .sBtn {
                    color: ${({ theme }) => theme.black};  
                  }
                .flink{
                    color: ${({ theme }) => theme.white} !important;
                    transition: all 0.50s linear;
                }
                .socialIcon{
                    color: ${({ theme }) => theme.white} !important;
                }
                .socialIcon:hover{z
                    background-color: ${({ theme }) => theme.white}  !important;
                    color: ${({ theme }) => theme.black} !important;
                }
                .banner{
                    background-color: ${({ theme }) => theme.black}  !important;
                    transition: all 0.50s linear;
                }
                .uploadIcon{
                    border:1px solid  ${({ theme }) => theme.white}  !important;
                    transition: all 0.50s linear;
                }
                .pBarValue{
                    color: ${({ theme }) => theme.black}  !important;
                    transition: all 0.50s linear;
                }
                .background span{
                    background: ${({ theme }) => theme.black}  !important;
                    box-shadow:0px 0px  10px ${({ theme }) => theme.white}  !important;,
                    0px 0px  40px ${({ theme }) => theme.white}  !important;,
                    0px 0px  80px ${({ theme }) => theme.white}  !important;,
                    0px 0px  120px ${({ theme }) => theme.white}  !important;,
                    0px 0px  200px ${({ theme }) => theme.white}  !important;;
                    transition: all 0.50s linear;
                }
                .background span:nth-child(n+1){
                    border:5px solid ${({ theme }) => theme.white}  !important;
                    transition: all 0.50s linear;
                }
                .logoutIcon{
                    color: ${({ theme }) => theme.black}  !important;
                    transition: all 0.50s linear;
                }
                .logoutBtn:hover .logoutIcon{
                    color: ${({ theme }) => theme.white}  !important;
                }
                // .banner{
                //     height: 825px  !important;
                // }
                }
                     @media (max-width: 1199px) {
                    
                        .banner{
                            height: 625px  !important;
                        }
                        // .makeStyles-aboutUs-34,
                        // .makeStyles-career-40{
                        //     padding:15.625rem 0 !important;
                        // }
                    }
                    @media (max-width: 767px) {
                    
                        .banner{
                            height: 468px  !important;
                        }
                        // .makeStyles-aboutUs-34,
                        // .makeStyles-career-40{
                        //     padding:15.625rem 0 !important;
                        // }
                    }

                    
                    @media (max-width: 1024px) {
            
                        .joinw{display:none;}
                    }
                
                .logoutBtn:hover{
                    background-color: ${({ theme }) => theme.black}  !important;
                    transition: all 0.50s linear;
                    border:1px solid ${({ theme }) => theme.white}  !important;
                }
                .tableContianer table{
                    background-color: ${({ theme }) => theme.white}  !important;
                }
                .tableContianer table th,
                .tableContianer table td{
                    color: ${({ theme }) => theme.black}  !important;
                }
                .blackBg{
                    background-color: ${({ theme }) => theme.black}  !important;
                    transition: all 0.50s linear;
                }
                .btext{
                    background-color: ${({ theme }) => theme.black}  !important;
                    transition: all 0.50s linear;
                }
                .btext h1{
                    color: ${({ theme }) => theme.white} !important; 
                    transition: all 0.50s linear;
                }
                
                       
                  
                .logoutBtn{
                    background-color: ${({ theme }) => theme.white}  !important;
                    border:"1px solid ${({ theme }) => theme.white}  !important;
                    transition: all 0.50s linear;
                }
        
              
      


    }   
  `