import React, { useState } from 'react'
import logo from '../logo.png';
import logo1 from '../logo1.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { Container } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CancelIcon from '@mui/icons-material/Cancel';
import List from '@mui/material/List';
import { Link } from 'react-scroll'
import { Fragment } from 'react';
const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: "#fff !important",
        padding: "10px",
        position: "fixed",
        width: "100%",
        zIndex: "1111",
        top: "0",
        left: "0",
        borderBottom: " 1px solid #e1e4e8",
    },
    leoruslogo: {
        height: "60px",
        width: "190px",
        [theme.breakpoints.down("sm")]: {
            height: "40px",
            width: "150px",
        }
    },
    leoruslogo1: {
        height: "60px",
        width: "190px",
        display: "none !important",
        [theme.breakpoints.down("sm")]: {
            height: "40px",
            width: "150px",
        }
    },
    appBar: {
        backgroundColor: "#fff !important",
        boxShadow: "none !important",
        color: "#000 !important",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "space-evenly",
            paddingRight: "40px !important",
            paddingLeft: "0px !important"
        }

    },
    iconButton: {
        display: "none !important",
        zIndex: "999",
        [theme.breakpoints.down("sm")]: {
            display: "block !important",
            zIndex: "999"
        }
    },
    navlinks: {
        // display: "block !important",
        display: "flex",
        alignItems: "flex-end",
        [theme.breakpoints.down("sm")]: {
            display: "none !important"

        }
    },
    navlink: {
        position: "relative",
        marginRight: "32px",
        [theme.breakpoints.down("sm")]: {
            marginRight: "0",

        }
    },
    link: {
        display: "flex",
        textDecoration: "none",
        alignItems: "center",
        color: "#24292e",
        fontSize: "16px",
        cursor: "pointer",
        height: "50px",
        position: "relative",
        fontWeight: 500,

        borderBottom: "3px solid transparent ",
        "&:after": {
            content: '""',
            width: "0",
            height: "3px",
            backgroundColor: "#000",
            position: "absolute",
            top: "47px",
            left: "50%",
            transition: "width .4s cubic-bezier(.5,.9,.25,1.15),left .4s cubic-bezier(.5,.9,.25,1.15)",
        },
        "&:hover::after": {
            width: "100%",
            left: "0",
            [theme.breakpoints.down("sm")]: {
                width: "0 !important",
                left: "0",
            }
        },
        "&.active": {
            content: '""',
            borderBottom: "3px solid #000 ",
            transition: "width .1s cubic-bezier(.5,.9,.25,1.15),left .4s cubic-bezier(.5,.9,.25,1.15)",
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center"
        }
    },
    linkSpan: {
        position: "relative",
    },
    cancelbtn: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: " center",
        padding: "10px 20px 25px"
    },
    cancelicon: {
        height: "25px !important",
        width: "25px !important",
    },
    navlinksMenu: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            padding: " 40px 70px"

        }
    },
    sideBarMenu: {
        zIndex: "9",
        height: "100vh",
        width: "100vw !important",
        // position: "absolute"
    }
}))

export const Header = () => {
    const classes = useStyles();
    const [value, setValue] = useState('one');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (anchor, onOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: onOpen });
    };


    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            className={`${classes.sideBarMenu} ${"sideBarMenu"}`}
        >
            <List>
                <div className={classes.cancelbtn}>
                    <CancelIcon className={`${classes.cancelicon} ${"cancelicon"}`} />
                </div>
                <div className={classes.navlinksMenu}>
                    <div className={`${classes.navlink} ${"navlink"}`}><Link to="about" className={`${classes.link} ${"link"}`} onClick={toggleDrawer(anchor, false)} spy={true} smooth={true}><span className={classes.linkSpan}>About Us</span></Link> </div>
                    <div className={`${classes.navlink} ${"navlink"}`}><Link to="career" className={`${classes.link} ${"link"}`} onClick={toggleDrawer(anchor, false)} spy={true} smooth={true}><span className={classes.linkSpan}>Career</span></Link></div>
                </div>
            </List>
        </Box>
    );

    return (
        <header className={`${classes.header} ${"header"}`}>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" className={`${classes.appBar} ${"appBar"}`}>
                        <Toolbar className={classes.toolbar}>

                            {['left'].map((anchor) => (
                                <Fragment key={anchor} >
                                    <MenuIcon className={`${classes.iconButton} ${"iconButton"}`} onClick={toggleDrawer(anchor, true)}>{anchor}</MenuIcon>
                                    <SwipeableDrawer
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                        onOpen={toggleDrawer(anchor, true)}
                                    >
                                        {list(anchor)}
                                    </SwipeableDrawer>
                                </Fragment>
                            ))}
                            <img src={logo} alt="leorus game" className={`${classes.leoruslogo} ${"leoruslogo"}`} />
                            <img src={logo1} alt="leorus game" className={`${classes.leoruslogo1} ${"leoruslogo1"}`} />
                            <div className={classes.navlinks}>
                                <div className={`${classes.navlink} ${"navlink"}`}><Link to="about" className={`${classes.link} ${"link"}`} spy={true} smooth={true}><span className={classes.linkSpan}>About Us</span></Link> </div>
                                <div className={`${classes.navlink} ${"navlink"}`}><Link to="career" className={`${classes.link} ${"link"}`} spy={true} smooth={true}><span className={classes.linkSpan}>Career</span></Link></div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Container>
        </header>
    );
}
