import React, { useState, useEffect } from 'react'
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import AdminLogin from './AdminLogin';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LogoutIcon from '@mui/icons-material/Logout';
import "firebase/auth";
import { app, storage } from '../Firebase';
import { FeaturedGames } from './FeaturedGames';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Button, CardActionArea, CardContent, CardMedia, Container, Fab, FormLabel, Grid, MenuItem, Modal, Typography } from '@mui/material';
import { Card, CardActions, FormControlLabel, Radio, RadioGroup, Snackbar, TextField, Select ,Tooltip } from '@material-ui/core';
import { Add, KeyboardReturn, Opacity, Title } from '@mui/icons-material';
import { db, auth, database } from "../Firebase"
import { padding, width } from '@mui/system';
import CircularProgressWithLabel from './PercentSpinner';
import AdminTable from './AdminTable';
import CircularStatic from './PercentSpinner';


const useStyles = makeStyles(theme => ({
    fabAdd: {
        position: "fixed",
        right: 20,
        bottom: 20,
    },
    fab: {
        backgroundColor: "#000  !important",
        color: "#fff  !important",
    },
    modalContainer: {
        width: "500px !important",
        height: "550px !important",
        backgroundColor: "#fff !important",
        boxShadow: "0 0 25px rgba(255,255,255,.6)",
        position: "absolute !important",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto !important",
        borderRadius: "20px",
        [theme.breakpoints.down("sm")]: {
            width: "100vw  !important",
            height: "100vh  !important",
            borderRadius: "0",
        }
    },
    form: {
        width: "100% !important",
    },
    item: {
        width: "100% !important",
        marginTop: theme.spacing(3),
    },
    text: {
        width: "100% !important",
        height: "40px !important",
        backgroundColor: "#fff",
        borderRadius: "10px",
        '&:focus': {
            outline: "0 !important",

        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
            borderColor: "#000",
            
        },
    },

    uploadbtn: {
        display: "flex",
        alignItems: "center ",
        margin: " 20px 0 0 !important",
        // justifyContent: " space-between",
        position: "relative",
    },
    uploadIcon: {
        height: "2em !important",
        width: "2em !important",
        color: "#fff !important",
        background: "#000 !important",
        borderRadius: " 50% !important",
        padding: "10px !important",
        position: "relative",
        zIndex: "9",

    },
    backBtn: {
        backgroundColor: "#000 !important",
        color: "#fff  !important",
        borderColor: "#fff  !important",

        '&:hover': {
            backgroundColor: "#fff !important",
            color: "#000  !important",
            borderColor: "#000  !important",
        },
    },
    logoutBtn: {
        backgroundColor: "#000 !important",
        borderRadius: "0 50% !important",
        padding: "10px !important",
        border: "1px solid #fff !important",
        '&:hover': {
            backgroundColor: "#fff !important",
            border: "1px solid #000 !important",
        },
        '&:hover $logoutIcon': {
            color: "#000 !important",
        },

    },
    logoutIcon: {
        color: "#fff !important",
    },
    cirPBar: {
        position: "absolute !important",
        top: "-4px !important",
        left: "-5px !important",
        width:"57px",
        height:"57px",
    },
}))
export const AddFeaturedGames = (props) => {
    const classes = useStyles();
    const [openBtn, setOpenBtn] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const [title, setTitle] = useState("")
    const [rank, setRank] = useState("")
    const [downloads, setDownload] = useState("")
    const [appleLink, setAppleLink] = useState("")
    const [playLink, setPlayLink] = useState("")
    const [videoLink, setVideoLink] = useState("")
    const [topRated, setTopRated] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [Error, setError] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);
    const alertHandleClick = () => {
        setOpenAlert(true);
        setOpenModal(false);
    };
    const alertHandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };
    const handleLogout = () => {
        auth.signOut();
    }
    const [openError, setOpenError] = useState(false);

    const handleClickError = () => {
        setOpenError(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };



    const [featured, setFeatured] = useState([])
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        const getPostFromFirebase = []
        const response = db.collection('FeaturedGames').get()
            .then(snapshot => {
                snapshot.docs.forEach(doc => {
                    let currentID = doc.id
                    let appObj = { ...doc.data(), ['id']: currentID }
                    getPostFromFirebase.push(doc.data())

                })
                setFeatured(getPostFromFirebase)
                setLoading(false);

            })

    }, [featured])


    const FeaturedTitle = (e) => {
        setTitle(e.target.value)
        featured && featured.map((featuredItem, index) => {
            if (featuredItem.title != title) {
                console.log("no exist");
                setError(false);
            }
            else if (featuredItem.title === title) {
                console.log("Title is already exist")
                // alert("Title is already exist")
                setError(true);
                setOpenModal(true);
            }

        })
    }



    const FeaturedAppleLink = (e) => {
        setAppleLink(e.target.value)
    }
    const FeaturedPlayLink = (e) => {
        setPlayLink(e.target.value)
    }
    const FeaturedVideoLink = (e) => {
        setVideoLink(e.target.value)
    }
    const [progress, setProgress] = useState(0);
    const formHandler = () => {

        if (progress === 100) {

            db.collection("FeaturedGames").add({
                title: title,
                rank: rank,
                appleLink: appleLink,
                playLink: playLink,
                videoLink: videoLink,
                imageUrl: imageUrl
            })
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);

                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });


            setOpenAlert(true);
            setOpenModal(false);
        }
        else {
            setOpenError(true);
            setOpenModal(true);
        }
    };

    const fileSelectedHandler = (e) => {
        const file = e.target.files[0]
        if (!file) return;
        const sotrageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(sotrageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const prog = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(prog);
            },
            (error) => console.log(error),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log("File available at", downloadURL);
                    setImageUrl(downloadURL)
                });

            }
        );
        const styles = theme => ({
            notchedOutline: {
                borderWidth: "1px",
                borderColor: "yellow !important"
            }
        });

    }
    return (
        <>
            <div>
                <div className={classes.fabAdd}>
                    <Tooltip title="Add" aria-label="add"  >
                        <Fab className={`${classes.fab}${"fab"}`} >
                            <Add onClick={handleOpen} />
                        </Fab>
                    </Tooltip>
                </div>
                <Tooltip title="Logout">
                    <Button onClick={handleLogout} className={`${classes.logoutBtn} ${"logoutBtn"}`}>< LogoutIcon className={`${classes.logoutIcon} ${"logoutIcon"}`} /></Button>
                </Tooltip>

            </div>
            <AdminTable />
            <Modal
                open={openModal}
            // onClose={handleClose}
            >
                <Container className={`${classes.modalContainer} ${"modalContainer"}`}>
                    <form className={classes.form}>
                        <div className={`${classes.uploadbtn} ${"uploadbtn"}`}>
                            <input type="file" accept="image/*" id="file1" onChange={fileSelectedHandler} style={{ display: "none" }} />
                            <label for="file1"><CloudUploadIcon className={`${classes.uploadIcon} ${"uploadIcon"}`} /></label>
                            <CircularStatic className={`${classes.cirPBar} ${"cirPBar"}`} value={progress} />
                        </div>
                        <div className={classes.item}>
                            <TextField
                                hiddenLabel
                                autoFocus={false}
                                className={classes.text}
                                style={{ width: "100%" }}
                                size="small"
                                variant="outlined"
                                id="outlined-multiline-static"
                                label="Title"
                                value={title}
                                onChange={FeaturedTitle}
                                error={Error}
                                // InputLabelProps={{ shrink: false }}
                                helperText={Error ? 'Title Already exists !' : ' '}
                                notched={false}
                            />

                        </div>
                        <div className={classes.item}>
                            {/* <TextField
                                className={classes.text}
                                style={{ width: "100%" }}
                                size="small"
                                variant="outlined"
                                id="outlined-multiline-static"
                                label="Rank"
                                value={rank}
                                onChange={(e) => setRank(e.target.value)}
                            /> */}
                            <Select
                                className={classes.text}
                                style={{ width: "100%" }}
                                variant="outlined"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Rank"
                                value={rank}
                                notched={false}
                                onChange={(e) => setRank(e.target.value)}
                            >
                                <MenuItem value={"Normal"}>Normal</MenuItem>
                                <MenuItem value={"Featured"}>Featured</MenuItem>
                            </Select>
                        </div>
                        <div className={classes.item}>
                            <TextField
                                // InputLabelProps={{ shrink: false }}
                                className={classes.text}
                                style={{ width: "100%" }}
                                size="small"
                                variant="outlined"
                                id="outlined-multiline-static"
                                label="Apple store Link"
                                value={appleLink}
                                // InputLabelProps={{ shrink: false }}
                                onChange={FeaturedAppleLink}
                            />
                        </div>
                        <div className={classes.item}>
                            <TextField
                                // InputLabelProps={{ shrink: false }}
                                className={classes.text}
                                style={{ width: "100%" }}
                                size="small"
                                variant="outlined"
                                id="outlined-multiline-static"
                                label="Play store Link"
                                value={playLink}
                                onChange={FeaturedPlayLink}
                            />
                        </div>
                        <div className={classes.item}>
                            <TextField
                                // InputLabelProps={{ shrink: false }}
                                className={classes.text}
                                style={{ width: "100%" }}
                                size="small"
                                variant="outlined"
                                id="outlined-multiline-static"
                                label="Youtube Link"
                                value={videoLink}
                                onChange={FeaturedVideoLink}
                            />
                        </div>
                        {/* <div className={classes.item}>
                            <FormLabel component="legend">Ranking</FormLabel>
                            <RadioGroup
                                className={classes.text}
                                aria-label="gender"
                                defaultValue="female"
                                name="radio-buttons-group"
                                variant="primary"
                                value={topRated}
                                onChange={(e) => setTopRated(e.target.value)}
                            >
                                <FormControlLabel value="topRated" control={<Radio size="small" />} label="Top Rank" />
                            </RadioGroup>
                        </div> */}

                        <div className={classes.item}>
                            <Button variant="outlined" className={`${classes.backBtn} ${"backBtn"}`} style={{ marginRight: 20 }} onClick={formHandler}>Add</Button>
                            <Button variant="outlined" className={`${classes.backBtn} ${"backBtn"}`} style={{ marginRight: 20 }} onClick={handleClose}>Cancel</Button>
                        </div>
                    </form>
                </Container>
            </Modal>
            <Snackbar open={openAlert} autoHideDuration={4000} onClose={alertHandleClose}>
                <Alert onClose={alertHandleClose} severity="success" sx={{ width: '100%' }}>
                    Success Fully Added
                </Alert>
            </Snackbar>

            {/* <Button variant="outlined" onClick={handleClickError}>
                Open success snackbar
            </Button> */}
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Please Enter Image
                </Alert>
            </Snackbar>
        </>
    )
}
