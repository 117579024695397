import React from 'react'
import "./contact.css"
export const ContactUs = () => {

  return (
  <div className='ksec'>
    <section className=''>
    
    <div className="section-header">
      <div className="container">
        <h2>Contact Us</h2>
        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
      </div>
    </div>
    
    <div className="container">
      <div className="row">
        
        <div className="contact-info">
        <div className="contact-info-item">
 
            
            <div className="contact-info-content">
              <h4>Founder</h4>
              <p>Syed Muhammad Sadiq Husaini</p>
            </div>
          </div>
          
          <div className="contact-info-item">
     
            
            <div className="contact-info-content">
              <h4>Address</h4>
              <p>Office # 606, LandMark<br/>
Plaza, Jail Road<br/>
Lahore</p>
            </div>
          </div>
          
          <div className="contact-info-item">
        
            
            <div className="contact-info-content">
              <h4>Email</h4>
              <p>sadiqhusaini@hotmail.com</p>
            </div>
          </div>
          
          <div className="contact-info-item">
        
            
            <div className="contact-info-content">
              <h4>Phone</h4>
              <p>+92 3074674186</p>
            </div>
          </div>
          

        </div>
        
        <div className="contact-form">
          <form action="" id="contact-form">
            <h2>Send Message</h2>
            <div className="input-box">
              <input type="text" required="true" name=""/>
              <span>Full Name</span>
            </div>
            
            <div className="input-box">
              <input type="email" required="true" name=""/>
              <span>Email</span>
            </div>
            
            <div className="input-box">
              <textarea required="true" name=""></textarea>
              <span>Type your Message...</span>
            </div>
            
            <div className="input-box">
              <input type="submit" value="Send" name=""/>
            </div>
          </form>
        </div>
        
      </div>
    </div>
  </section>
  </div>
  )
}


